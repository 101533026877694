import type { ContractInsuranceFromBE, GetContractRequestsInfoResponse } from 'api/eContractingAPI'
import { CallAPIAction, Status } from 'api/types'
import { ADDITIONAL_PREFERENCES_SEPARATOR, INSTALLMENT_KEYS } from 'common/constants'
import { ContractExtensionStatus, ServiceTypes, StringRecord } from 'common/types/commonTypes'
import type { ContractInsurance, ContractRequest, ContractRequestOffer } from 'common/types/transformedRespTypes'
import { convertRating, formatDateForUI } from 'common/utils/common'
import Actions from 'redux/actions'

type State = ContractRequest & Status

const initialState = {
  contractRequests: {
    visaId: 0,
    ageRangeId: 0,
    religionId: 0,
    cityId: 0,
    workExperience: 0,
    preferences: '',
    additionalPreferences: '',
    requestType: ServiceTypes.NONE,
    workerName: '',
    workerPassport: '',
    workerMobile: '',
    workerPassportExpiryDate: '',
    followupNumber: 0,
    id: 0,
    createdAt: '',
    cancellationDate: '',
    newStatus: '',
    isUnifiedWithInitialVisa: false,
    borderNumber: 0,
    offers: {},
    v2: false,
  },
  isLoading: false,
  success: false,
  fail: false,
}

const convertContractExtensionStats = (value: number | null) => {
  switch (value) {
    case null:
      return ContractExtensionStatus.PENDING
    case 1:
      return ContractExtensionStatus.APPROVED
    case 0:
      return ContractExtensionStatus.REJECTED
    default:
      return ContractExtensionStatus.PENDING
  }
}

const convertInsurance = (insurances: ContractInsuranceFromBE[], type: number): ContractInsurance | null => {
  const typeInsuranse = insurances.find((insurance) => insurance.insurance_type === type)

  if (typeInsuranse) {
    return {
      amount: typeInsuranse.amount,
      insuranceCompany: {
        id: typeInsuranse.insurance_company_id,
        label: typeInsuranse.insurance_company_name_ar,
        labelEn: typeInsuranse.insurance_company_name_en,
      },
      policyIssueDate: formatDateForUI(typeInsuranse.policy_issue_date),
      policyEffectiveDate: formatDateForUI(typeInsuranse.policy_effective_date),
      status: typeInsuranse.status,
      serviceProvider: typeInsuranse.service_provider,
      insuranceType: typeInsuranse.insurance_type,
      policyNumber: typeInsuranse.policy_number,
      policyExpiryDate: formatDateForUI(typeInsuranse?.policy_expiry_date),
    }
  } else {
    return null
  }
}

export const convertResponseToState = (resp: GetContractRequestsInfoResponse): ContractRequest => {
  const [preferences, additionalPreferences] = (resp.contractRequests.preferences || '').split(
    ADDITIONAL_PREFERENCES_SEPARATOR,
  )
  const offers = resp.contractRequests.offers.reduce<StringRecord<ContractRequestOffer>>((acc, offerFromBe) => {
    const vat = offerFromBe.contract?.settings?.VAT || offerFromBe.contract?.bill?.vat_setting.value
    const fee =
      offerFromBe.contract?.bill?.settings.find((value) => (INSTALLMENT_KEYS as Set<string>).has(value.key))?.value ||
      0

    const cvs = offerFromBe.cvs?.map((cv) => ({
      id: cv.id,
      order: cv.order,
      candidateName: cv.candidate_name,
      offerId: cv.offer_id,
      portfolioPath: cv.portfolio_path,
      cvPath: cv.cv_path,
      salary: cv.salary,
    }))

    return {
      ...acc,
      [offerFromBe.id]: {
        id: offerFromBe.id,
        cvs: cvs?.sort((a, b) => a.order - b.order) || [],
        contract: offerFromBe.contract
          ? {
              id: offerFromBe.contract.id,
              createdAt: offerFromBe.contract.created_at,
              newStatus: offerFromBe.contract.new_status,
              idNumber: offerFromBe.contract.id_number,
              visaId: offerFromBe.contract.visa_id,
              canceledBy: offerFromBe.contract.canceled_by,
              cancelledById: offerFromBe.contract.cancelled_by_id,
              isDirectContract: offerFromBe.contract.is_direct_contract,
              sentToSabb: !!offerFromBe.contract.sent_to_sabb,
              proId: offerFromBe.contract.pro_id,
              vat: typeof vat === 'string' ? Number(vat) : undefined,
              bill: offerFromBe.contract.bill
                ? {
                    id: offerFromBe.contract.bill.id,
                    createdAt: offerFromBe.contract.bill.created_at,
                    status: offerFromBe.contract.bill.status,
                    paidDate: offerFromBe.contract.bill.paid_date || '',
                    expiryDate: offerFromBe.contract.bill.expiry_date,
                    amount: offerFromBe.contract.bill.amount,
                    sadadBillNumber: offerFromBe.contract.bill.sadad_bill_number || '',
                    paymentMethod: offerFromBe.contract.bill.payment_method,
                    installmentFee: fee ? parseInt(fee, 10) : 0,
                    zatcaInvoices: offerFromBe.contract.bill.z_a_t_c_a_invoices?.map((invoice) => ({
                      id: invoice.id,
                      supplierType: invoice.supplier_type,
                    })),
                  }
                : null,
              contractCancellationRequests:
                offerFromBe.contract.contract_cancellation_requests &&
                offerFromBe.contract.contract_cancellation_requests?.length > 0
                  ? offerFromBe.contract.contract_cancellation_requests.map((item) => ({
                      id: item.id,
                      contractId: item.contract_id,
                      status: item.status,
                      reason: item.reason,
                      createdAt: item.created_at,
                    }))
                  : null,
              extensionRequest: offerFromBe.contract.extension_request // won't be available inside contract request list endpoint
                ? {
                    createdAt: offerFromBe.contract.extension_request.created_at,
                    isAccepted: convertContractExtensionStats(offerFromBe.contract.extension_request.is_accepted),
                  }
                : null,
              insurance:
                offerFromBe.contract.insurance && !Array.isArray(offerFromBe.contract.insurance)
                  ? {
                      amount: offerFromBe.contract.insurance.amount,
                      insuranceCompany: {
                        id: offerFromBe.contract.insurance.insurance_company_id,
                        label: offerFromBe.contract.insurance.insurance_company_name_ar,
                        labelEn: offerFromBe.contract.insurance.insurance_company_name_en,
                      },
                      policyIssueDate: formatDateForUI(offerFromBe.contract.insurance.policy_issue_date),
                      policyEffectiveDate: formatDateForUI(offerFromBe.contract.insurance.policy_effective_date),
                      status: offerFromBe.contract.insurance.status,
                      serviceProvider: offerFromBe.contract.insurance?.service_provider,
                      insuranceType: offerFromBe.contract.insurance?.insurance_type,
                      policyNumber: offerFromBe.contract.insurance?.policy_number || '-',
                      policyExpiryDate: formatDateForUI(offerFromBe.contract.insurance?.policy_expiry_date),
                    }
                  : null,
              ...(offerFromBe.contract.insurance &&
                Array.isArray(offerFromBe.contract.insurance) &&
                offerFromBe.contract.insurance.length && {
                  contractInsurance: convertInsurance(offerFromBe.contract.insurance, 1),
                  healthInsurance: convertInsurance(offerFromBe.contract.insurance, 2),
                }),
            }
          : null,
        contractText: offerFromBe.contract_text
          ? {
              id: offerFromBe.contract_text?.id,
              offerId: offerFromBe.contract_text?.offer_id,
              text: offerFromBe.contract_text?.text,
            }
          : null,
        workerSalary: offerFromBe.worker_salary || 0,
        duration: offerFromBe.duration,
        userStatus: offerFromBe.user_status,
        proStatus: offerFromBe.pro_status,
        proResponseTime: offerFromBe.pro_response_time || '',
        pro: offerFromBe.pro
          ? {
              id: offerFromBe.pro.id,
              reviewsCount: offerFromBe.pro.reviews_count,
              rating: convertRating(offerFromBe.pro.rating),
              acceptanceRate: Math.round(parseFloat(offerFromBe.pro.acceptance_rate)),
              name: offerFromBe.pro.name,
              address: {
                cityId: offerFromBe.pro.address.city_id,
                mobileNumber: offerFromBe.pro.address.mobile_number,
                phoneNumber: offerFromBe.pro.address.phone_number,
                email: offerFromBe.pro.address.email,
                address: offerFromBe.pro.address.address,
                logoUrl: offerFromBe.pro.address.logo_url,
                location: offerFromBe.pro.address.location,
              },
              type: offerFromBe.pro.type,
              latestLicense: {
                licenseNumber: offerFromBe.pro.latest_license.license_number,
              },
            }
          : null,
        price: offerFromBe.price || 0,
        deliveryPrice: offerFromBe.delivery_price || 0,
        isCancelled: !!offerFromBe.is_cancelled,
        serviceId: offerFromBe.service_id,
        extraAddons:
          offerFromBe.extra_addons && offerFromBe.extra_addons.length
            ? offerFromBe.extra_addons.map((addon) => ({
                id: addon.id,
                offerRequestId: addon.offer_request_id,
                recruitedAddonCategoryId: addon.recruited_addon_category_id,
                note: addon.note,
                cost: addon.cost,
                isUserAccepted: addon.is_user_accepted,
                createdAt: addon.created_at,
                updatedAt: addon.updated_at,
                serviceProvider: addon.service_provider,
                duration: addon.duration,
              }))
            : [],
      },
    }
  }, {})
  return {
    contractRequests: {
      visaId: resp.contractRequests.visa_id,
      requestType: resp.contractRequests.request_type,
      ageRangeId: resp.contractRequests.age_range_id ? parseInt(resp.contractRequests.age_range_id, 10) : 0,
      religionId: parseInt(resp.contractRequests.religion_id, 10),
      cityId: resp.contractRequests.city_id,
      workExperience: parseInt(resp.contractRequests.work_experience, 10),
      preferences,
      additionalPreferences,
      workerName: resp.contractRequests.worker_name || '',
      workerPassport: resp.contractRequests.worker_passport || '',
      workerPassportExpiryDate: resp.contractRequests.worker_passport_expiry_date || '',
      workerMobile: resp.contractRequests.worker_mobile || '',
      followupNumber: resp.contractRequests.followup_number,
      id: resp.contractRequests.id,
      createdAt: resp.contractRequests.created_at,
      cancellationDate: resp.contractRequests.cancellation_date || '',
      newStatus: resp.contractRequests.new_status,
      isUnifiedWithInitialVisa: resp.contractRequests.is_unified_with_initial_visa,
      borderNumber: resp.contractRequests.border_number,
      offers,
      v2: !!resp.contractRequests.v2,
    },
  }
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_REQUEST_INFO_ASYNC,
    Actions.GET_CONTRACT_REQUEST_INFO_SUCCESS,
    Actions.GET_CONTRACT_REQUEST_INFO_FAIL,
    GetContractRequestsInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_REQUEST_INFO_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_CONTRACT_REQUEST_INFO_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_CONTRACT_REQUEST_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}

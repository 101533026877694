import cookies from 'js-cookie'
import moment from 'moment'

import { APP_ROOT_DOMAIN } from 'common/envConstants'
export default class JWTToken {
  private name: string
  constructor(name: string, rmToken?: string) {
    this.name = name
    this.token = cookies.get(this.name) || rmToken || ''
  }

  get token() {
    return cookies.get(this.name) || ''
  }

  set token(token: string) {
    let expDate = moment().add(5, 'minutes').valueOf()
    try {
      expDate = this.getExpirationDate(token)
      // some tokens can't be parsed in case of initital sign in token
      // in this case we set default exp date (5 minutes)
      // eslint-disable-next-line no-empty
    } catch (e) {}
    cookies.set(this.name, token, {
      expires: new Date(expDate),
      secure: true,
      sameSite: 'Lax',
      domain: APP_ROOT_DOMAIN,
    })
  }

  get laborerTokenExpiresDate() {
    return localStorage.getItem('laborerTokenExpiresDate') || ''
  }

  set laborerTokenExpiresDate(date: string) {
    localStorage.setItem('laborerTokenExpiresDate', date)
  }

  removeToken() {
    cookies.remove(this.name, { domain: APP_ROOT_DOMAIN, secure: true, sameSite: 'Lax' })
    localStorage.removeItem('laborerTokenExpiresDate')
  }

  private static urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/').replace(/JWT /g, '')
    switch (output.length % 4) {
      case 0: {
        break
      }
      case 2: {
        output += '=='
        break
      }
      case 3: {
        output += '='
        break
      }
      default: {
        throw new Error('Illegal base64url string!')
      }
    }
    return decodeURIComponent(window.atob(output))
  }

  private decodeToken() {
    const parts = this.token.split('.')
    if (parts.length !== 3) {
      throw new Error('JWT must be 3 parts!')
    }
    const decoded = JWTToken.urlBase64Decode(parts[1])
    if (!decoded) {
      throw new Error('Cannot decode token!')
    }
    return JSON.parse(decoded)
  }

  getExpirationDate(token?: string) {
    if (token || this.token) {
      return +new Date(this.decodeToken().exp * 1000)
    }
    return 0
  }

  getIssuanceDate() {
    if (this.token) {
      return +new Date(this.decodeToken().iat * 1000)
    }
    return 0
  }

  getUsername() {
    if (this.token) {
      return this.decodeToken().sub
    }
    return 0
  }

  getUserType() {
    if (this.token) {
      return this.decodeToken().is_premium
    }
  }

  getUserRole() {
    if (this.token) {
      return this.decodeToken().type
    }
  }
}

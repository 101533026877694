import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import authManager, { userProfile as emptyUserProfile } from 'common/utils/auth/authManager'
import { getUserData, logOut, updateAuth } from 'redux/actionCreators/evisaAPI/auth'
import SuspensePreloader from 'ui-components/loading/SuspensePreloader/SuspensePreloader'
import { RootState } from 'redux/rootReducer'
import { authLogout } from 'redux/actionCreators/evisaAPI/evisaAPI'

const EMPTY_USER_PROFILE_STR = JSON.stringify(emptyUserProfile)

const AutoLoginFallback: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch()
  const [userProfileReady, setUserProfileReady] = useState<boolean>(false)
  const { completed, errorMessage } = useSelector<RootState, RootState['authFlow']>((state) => state.authFlow)
  useEffect(() => {
    // check if user logged in from new ui (in the new ui we don't store userData in localStorage)
    if (
      authManager.isAuthenticated() &&
      (JSON.stringify(authManager.getUserProfileData()) === EMPTY_USER_PROFILE_STR ||
        authManager.getUserProfileData().idNumber !== authManager.getUserNameFromEvisaToken(true))
    ) {
      dispatch(getUserData())
    } else {
      setUserProfileReady(true)
    }
  }, [dispatch])

  useEffect(() => {
    if (!userProfileReady && completed) {
      if (errorMessage) {
        dispatch(authLogout())
        dispatch(logOut())
      } else {
        dispatch(
          updateAuth({
            loggedIn: authManager.isAuthenticated(),
            logOutWarning: authManager.isReadyForAutoLogOut(),
            userProfile: authManager.getUserProfileData(),
          }),
        )
        setUserProfileReady(true)
      }
    }
  }, [completed, dispatch, errorMessage, userProfileReady])

  return <>{userProfileReady ? children : <SuspensePreloader wholeView />}</>
}

export default AutoLoginFallback

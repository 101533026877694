import { ServiceTypes } from 'common/types/commonTypes'
import { MarketFilterStateType, setMarketFilter } from 'redux/actionCreators/marketFilter'
import Actions from 'redux/actions'

const initialState: MarketFilterStateType = {
  typeOfRecruitment: ServiceTypes.NONE,
  occupation: undefined,
  nationality: undefined,
  priceSort: 'asc',
  ratingSort: 'desc',
  officeNameSearch: '',
  costRange: {
    min: undefined,
    max: undefined,
  },
  durationRange: {
    min: undefined,
    max: undefined,
  },
  arrivalCityId: undefined,
  religion: undefined,
  starsRating: 0,
  acceptanceRateRange: {
    min: undefined,
    max: undefined,
  },
  page: 0,
  clearPagesCount: false,
  extraPreferences: [],
}

const marketFilter = (
  state: MarketFilterStateType = initialState,
  action: ReturnType<typeof setMarketFilter>,
): MarketFilterStateType => {
  switch (action.type) {
    case Actions.SET_MARKET_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      }
    default:
      return state
  }
}

export default marketFilter

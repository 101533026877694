import cookies from 'js-cookie'

import { APP_ROOT_DOMAIN } from 'common/envConstants'

export default class CookieSessionStorageData<T> {
  private key: string
  private initialValue: T
  constructor(valueKey: string, value: T) {
    this.key = valueKey
    this.initialValue = value
  }
  getItemKey() {
    return this.key
  }
  get data(): T {
    const data = cookies.get(this.key)
    if (data) {
      return JSON.parse(data) as T
    }
    return this.initialValue
  }
  set data(value: T) {
    cookies.set(this.key, JSON.stringify(value), {
      secure: true,
      sameSite: 'Lax',
      domain: APP_ROOT_DOMAIN,
    })
  }

  removeData() {
    cookies.remove(this.key, { domain: APP_ROOT_DOMAIN, secure: true, sameSite: 'Lax' })
  }
}

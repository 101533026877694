import moment from 'moment'

import HouseKeeper from 'images/workers/house-keeper.svg'
import Nanny from 'images/workers/nanny.svg'
import Driver from 'images/workers/driver.svg'
import HouseCook from 'images/workers/house-cook.svg'
import Tailor from 'images/workers/tailor.svg'
import Physiotherapist from 'images/workers/physiotherapist.svg'
import Waiter from 'images/workers/waiter.svg'
import Farmer from 'images/workers/farmer.svg'
// import Teacher from 'images/workers/teacher.svg'
// import SpeechAndHearing from 'images/workers/speech-and-hearing.svg'
import Butler from 'images/workers/butler.svg'
import HouseManager from 'images/workers/house-manager.svg'
import HouseGuard from 'images/workers/house-guard.svg'
import PersonalHelper from 'images/workers/personal-helper.svg'
import HouseNurse from 'images/workers/house-nurse.svg'
import CoffeeServant from 'images/workers/coffee-servant.svg'
import HouseKeeperVip from 'images/vip/workers/house-keeper.svg'
import NannyVip from 'images/vip//workers/nanny.svg'
import DriverVip from 'images/vip//workers/driver.svg'
import HouseCookVip from 'images/vip//workers/house-cook.svg'
import TailorVip from 'images/vip//workers/tailor.svg'
import PhysiotherapistVip from 'images/vip//workers/physiotherapist.svg'
import WaiterVip from 'images/vip//workers/waiter.svg'
import FarmerVip from 'images/vip//workers/farmer.svg'
// import SpeechAndHearingVip from 'images/vip//workers/speech-and-hearing.svg'
import ButlerVip from 'images/vip//workers/butler.svg'
import HouseManagerVip from 'images/vip//workers/house-manager.svg'
import HouseGuardVip from 'images/vip//workers/house-guard.svg'
import PersonalHelperVip from 'images/vip//workers/personal-helper.svg'
import HouseNurseVip from 'images/vip//workers/house-nurse.svg'
import CoffeeServantVip from 'images/vip//workers/coffee-servant.svg'

enum BackEndEnvTypes {
  LOCAL = 'local',
  UAT = 'uat',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
console.warn(`Environment: ${process.env.REACT_APP_NODE_ENV}`)

export const EVISA_BASE_API_URL = process.env.REACT_APP_EVISA_BASE_URL
export const X_API_KEY = process.env.REACT_APP_X_API_KEY
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const ECONTRACTING_API_URL = process.env.REACT_APP_ECONTRACT_BASE_URL
export const REFUND_BASE_URL = process.env.REACT_APP_REFUND_BASE_URL
export const PROS_BASE_API_URL = process.env.REACT_APP_PROS_BASE_URL
export const SURVEY_BASE_API_URL = process.env.REACT_APP_SURVEY_BASE_URL
export const JIRA_MUSANED_API_URL = process.env.REACT_APP_JIRA_MUSANED_BASE
export const WPS_MUSANED_API_URL = process.env.REACT_APP_WPS_MUSANED_BASE
export const CONTRACT_AUTH_API_BASE_URL = process.env.REACT_APP_CONTRACT_AUTH_API_BASE_URL
export const NEW_DL_TRANSFER_API_BASE_URL = process.env.REACT_APP_NEW_DL_TRANSFER_API_BASE_URL
export const DL_IND_TO_IND_API_BASE = process.env.REACT_APP_DL_IND_TO_IND_API_BASE
export const MUSANED_PDF_BASE = process.env.REACT_APP_MUSANED_PDF_BASE
export const TAKAMOL_BASE_URL = process.env.REACT_APP_TAKAMOL_BASE_URL
export const DL_LEASING_API_BASE = process.env.REACT_APP_DL_LEASING_API_BASE
export const LABORER_INTERVIEW_API_URL = process.env.REACT_APP_LABORER_INTERVIEW_API_BASE_URL

export const TAKAMOL_VAT_NUMBER = process.env.REACT_APP_TAKAMOL_VAT_NUMBER || ''
export const SADAD_INVOICE_NUMBER = process.env.REACT_APP_SADAD_INVOICE_NUMBER
export const APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''
export const REACT_APP_GIT_SHA = process.env.REACT_APP_GIT_SHA || ''

export const APP_ENV =
  process.env.REACT_APP_NODE_ENV === BackEndEnvTypes.UAT ? BackEndEnvTypes.STAGING : process.env.REACT_APP_NODE_ENV

export const IS_PROD = process.env.REACT_APP_NODE_ENV === BackEndEnvTypes.PRODUCTION

export const IS_DEV = !IS_PROD

export const IS_LOCAL_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development'

export const SERVICE_DESK_ID = IS_PROD ? 17 : 12

export const REQUEST_TYPE_ID = IS_PROD ? 174 : 96

export const NOTICE_PRINT_URL = IS_PROD
  ? 'https://ajeer.qiwa.sa/api/2.0/visitors/eligibility/print/:hash/:userId'
  : 'https://ajeer.qiwa.info/api/2.0/visitors/eligibility/print/:hash/:userId'

export const APP_ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN

export const NEW_UI_BASE_URL = process.env.REACT_APP_NEW_UI_BASE_URL

const PRODUCTION_OCCUPATIONS = [
  {
    imgSrc: HouseKeeper,
    imgVipSrc: HouseKeeperVip,
    code: 6132075,
    type: 'predefinedOccupations.houseKeeper',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 1,
      },
      {
        key: 'predefinedOccupations.female',
        value: 5,
      },
    ],
  },
  {
    imgSrc: Nanny,
    imgVipSrc: NannyVip,
    code: 6132125,
    type: 'predefinedOccupations.nanny',
    options: {
      key: 'predefinedOccupations.female',
      value: 2,
    },
  },
  {
    imgSrc: CoffeeServant,
    imgVipSrc: CoffeeServantVip,
    code: 6411155,
    type: 'predefinedOccupations.coffeeServant',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 359,
      },
      {
        key: 'predefinedOccupations.female',
        value: 401,
      },
    ],
  },
  {
    imgSrc: Driver,
    imgVipSrc: DriverVip,
    code: 9141055,
    type: 'predefinedOccupations.driver',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 4,
      },
      {
        key: 'predefinedOccupations.female',
        value: 364,
      },
    ],
  },
  {
    imgSrc: HouseCook,
    imgVipSrc: HouseCookVip,
    code: 6411125,
    type: 'predefinedOccupations.houseCook',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 10,
      },
      {
        key: 'predefinedOccupations.female',
        value: 13,
      },
    ],
  },
  {
    imgSrc: Tailor,
    imgVipSrc: TailorVip,
    code: 6411085,
    type: 'predefinedOccupations.tailor',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 369,
      },
      {
        key: 'predefinedOccupations.female',
        value: 54,
      },
    ],
  },
  {
    imgSrc: Physiotherapist,
    imgVipSrc: PhysiotherapistVip,
    code: 6411201,
    type: 'predefinedOccupations.physeotherapist',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 21,
      },
      {
        key: 'predefinedOccupations.female',
        value: 17,
      },
    ],
  },
  {
    imgSrc: Waiter,
    imgVipSrc: WaiterVip,
    code: 6411165,
    type: 'predefinedOccupations.waiter',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 44,
      },
      {
        key: 'predefinedOccupations.female',
        value: 51,
      },
    ],
  },
  {
    imgSrc: Farmer,
    imgVipSrc: FarmerVip,
    code: 6411145,
    type: 'predefinedOccupations.farmer',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 42,
      },
      {
        key: 'predefinedOccupations.female',
        value: 400,
      },
    ],
  },
  // {
  //   imgSrc: Teacher,
  //   code: 6411072,
  //   type: 'predefinedOccupations.teacher',
  //   options: [
  //     {
  //       key: 'predefinedOccupations.male',
  //       value: 36,
  //     },
  //     {
  //       key: 'predefinedOccupations.female',
  //       value: 37,
  //     },
  //   ],
  // },
  // {
  //   imgSrc: SpeechAndHearing,
  //   imgVipSrc: SpeechAndHearingVip,
  //   code: 6411231,
  //   type: 'predefinedOccupations.speechAndHearing',
  //   options: [
  //     {
  //       key: 'predefinedOccupations.male',
  //       value: 35,
  //     },
  //     {
  //       key: 'predefinedOccupations.female',
  //       value: 40,
  //     },
  //   ],
  // },
  {
    imgSrc: Butler,
    imgVipSrc: ButlerVip,
    code: 6411175,
    type: 'predefinedOccupations.butler',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 57,
      },
      {
        key: 'predefinedOccupations.female',
        value: 60,
      },
    ],
  },
  {
    imgSrc: HouseManager,
    imgVipSrc: HouseManagerVip,
    code: 6411135,
    type: 'predefinedOccupations.houseManager',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 344,
      },
      {
        key: 'predefinedOccupations.female',
        value: 63,
      },
    ],
  },
  {
    imgSrc: HouseGuard,
    imgVipSrc: HouseGuardVip,
    code: 6411035,
    type: 'predefinedOccupations.houseGuard',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 355,
      },
      {
        key: 'predefinedOccupations.female',
        value: 398,
      },
    ],
  },
  {
    imgSrc: PersonalHelper,
    imgVipSrc: PersonalHelperVip,
    code: 6411264,
    type: 'predefinedOccupations.personalHelper',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 363,
      },
      {
        key: 'predefinedOccupations.female',
        value: 405,
      },
    ],
  },
  {
    imgSrc: HouseNurse,
    imgVipSrc: HouseNurseVip,
    code: 6411192,
    type: 'predefinedOccupations.houseNurse',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 6,
      },
      {
        key: 'predefinedOccupations.female',
        value: 7,
      },
    ],
  },
]

const STAGING_UAT_OCCUPATIONS = [
  {
    imgSrc: HouseKeeper,
    imgVipSrc: HouseKeeperVip,
    code: 6132075,
    type: 'predefinedOccupations.houseKeeper',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 1,
      },
      {
        key: 'predefinedOccupations.female',
        value: 5,
      },
    ],
  },
  {
    imgSrc: Nanny,
    imgVipSrc: NannyVip,
    code: 6132125,
    type: 'predefinedOccupations.nanny',
    options: {
      key: 'predefinedOccupations.female',
      value: 2,
    },
  },
  {
    imgSrc: Driver,
    imgVipSrc: DriverVip,
    code: 9141055,
    type: 'predefinedOccupations.driver',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 4,
      },
      {
        key: 'predefinedOccupations.female',
        value: 221,
      },
    ],
  },
  {
    imgSrc: HouseCook,
    imgVipSrc: HouseCookVip,
    code: 6411125,
    type: 'predefinedOccupations.houseCook',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 8,
      },
      {
        key: 'predefinedOccupations.female',
        value: 9,
      },
    ],
  },
  {
    imgSrc: Tailor,
    imgVipSrc: TailorVip,
    code: 6411085,
    type: 'predefinedOccupations.tailor',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 26,
      },
      {
        key: 'predefinedOccupations.female',
        value: 211,
      },
    ],
  },
  {
    imgSrc: Physiotherapist,
    imgVipSrc: PhysiotherapistVip,
    code: 6411201,
    type: 'predefinedOccupations.physeotherapist',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 10,
      },
      {
        key: 'predefinedOccupations.female',
        value: 11,
      },
    ],
  },
  {
    imgSrc: Waiter,
    imgVipSrc: WaiterVip,
    code: 6411165,
    type: 'predefinedOccupations.waiter',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 3,
      },
      {
        key: 'predefinedOccupations.female',
        value: 29,
      },
    ],
  },
  {
    imgSrc: Farmer,
    imgVipSrc: FarmerVip,
    code: 6411145,
    type: 'predefinedOccupations.farmer',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 213,
      },
      {
        key: 'predefinedOccupations.female',
        value: 225,
      },
    ],
  },
  // {
  //   imgSrc: Teacher,
  //   code: 6411072,
  //   type: 'predefinedOccupations.teacher',
  //   options: [
  //     {
  //       key: 'predefinedOccupations.male',
  //       value: 14,
  //     },
  //     {
  //       key: 'predefinedOccupations.female',
  //       value: 15,
  //     },
  //   ],
  // },
  // {
  //   imgSrc: SpeechAndHearing,
  //   imgVipSrc: SpeechAndHearingVip,
  //   code: 6411231,
  //   type: 'predefinedOccupations.speechAndHearing',
  //   options: [
  //     {
  //       key: 'predefinedOccupations.male',
  //       value: 12,
  //     },
  //     {
  //       key: 'predefinedOccupations.female',
  //       value: 218,
  //     },
  //   ],
  // },
  {
    imgSrc: Butler,
    imgVipSrc: ButlerVip,
    code: 6411175,
    type: 'predefinedOccupations.butler',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 216,
      },
      {
        key: 'predefinedOccupations.female',
        value: 127,
      },
    ],
  },
  {
    imgSrc: HouseManager,
    imgVipSrc: HouseManagerVip,
    code: 6411135,
    type: 'predefinedOccupations.houseManager',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 212,
      },
      {
        key: 'predefinedOccupations.female',
        value: 128,
      },
    ],
  },
  {
    imgSrc: HouseGuard,
    imgVipSrc: HouseGuardVip,
    code: 6411035,
    type: 'predefinedOccupations.houseGuard',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 207,
      },
      {
        key: 'predefinedOccupations.female',
        value: 223,
      },
    ],
  },
  {
    imgSrc: PersonalHelper,
    imgVipSrc: PersonalHelperVip,
    code: 6411264,
    type: 'predefinedOccupations.personalHelper',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 219,
      },
      {
        key: 'predefinedOccupations.female',
        value: 230,
      },
    ],
  },
  {
    imgSrc: HouseNurse,
    imgVipSrc: HouseNurseVip,
    code: 6411192,
    type: 'predefinedOccupations.houseNurse',
    options: [
      {
        key: 'predefinedOccupations.male',
        value: 6,
      },
      {
        key: 'predefinedOccupations.female',
        value: 7,
      },
    ],
  },
]

type OccupationDataItemOption = {
  key: string
  value: number
}

export type OccupationDataItem = {
  imgSrc: string
  imgVipSrc: string
  type: string
  code: number
  options:
    | OccupationDataItemOption
    | Array<{
        key: string
        value: number
      }>
}

export const OCCUPATION_DATA: Array<OccupationDataItem> = IS_PROD ? PRODUCTION_OCCUPATIONS : STAGING_UAT_OCCUPATIONS

export const NEW_MAAROFA_FLOW_START_DATE = IS_DEV ? '2021-10-25T00:00:00.000Z' : '2021-10-26T12:00:00.000Z'

export const CAPTCHA_SITE_KEY = IS_PROD
  ? '6LehHrwUAAAAAI4i8LyL4pNtK1g9F0uan1s7mIeM'
  : '6LcsraMqAAAAAKcrKccO6NvQ61yl01VaQJMVu-uC'

export type SupportTicketOption = {
  id: string
  isAttachmentRequired: boolean
  labelKey: string
}

const STAGING_SUPPORT_TICKETS_TYPES: Array<SupportTicketOption> = [
  {
    id: '488',
    isAttachmentRequired: true,
    labelKey: 'supportTicketType.technicalIssue',
  },
  {
    id: '501',
    isAttachmentRequired: false,
    labelKey: 'supportTicketType.inquiry',
  },
  {
    id: '502',
    isAttachmentRequired: false,
    labelKey: 'supportTicketType.suggestions',
  },
  {
    id: '489',
    isAttachmentRequired: true,
    labelKey: 'supportTicketType.financialIssue',
  },
]

const PRODUCTION_SUPPORT_TICKETS_TYPES: Array<SupportTicketOption> = [
  {
    id: '356',
    isAttachmentRequired: true,
    labelKey: 'supportTicketType.technicalIssue',
  },
  {
    id: '335',
    isAttachmentRequired: false,
    labelKey: 'supportTicketType.inquiry',
  },
  {
    id: '336',
    isAttachmentRequired: false,
    labelKey: 'supportTicketType.suggestions',
  },
  {
    id: '338',
    isAttachmentRequired: true,
    labelKey: 'supportTicketType.financialIssue',
  },
]

export const SUPPORT_TICKETS_TYPES = (
  IS_PROD ? PRODUCTION_SUPPORT_TICKETS_TYPES : STAGING_SUPPORT_TICKETS_TYPES
).reduce<Record<string, SupportTicketOption>>(
  (acc, val) => ({
    [val.id]: val,
    ...acc,
  }),
  {},
)

export const INSURANCE_BENEFITS_FILE_URL = IS_DEV
  ? 'https://frontend.musaned.tamkeen.live/The_Standard_Insurance_Policy_on_Domestic_Workers_Contract.pdf'
  : 'https://musaned.com.sa/The_Standard_Insurance_Policy_on_Domestic_Workers_Contract.pdf'

export const NEW_BILL_ISSUANCE_START_DATE = IS_DEV ? '2021-11-29T09:00:00.000Z' : '2021-12-03T21:00:00.000Z'

// TODO remove this service constants after functionality will be tested
export const ENABLE_PREVIOUSLY_UPLOADED = IS_DEV

export const ENABLE_WPS_COMMITMENT = false

// TODO update jenkins file & secrets for production

export const ENABLE_CUSTOMER_SERVICES = IS_DEV

// temporarily to test on staging

export const ENABLE_COMPLAINTS_CHANGES = IS_DEV

export const ENABLE_NEW_VISA_INQUIRY = IS_DEV

export const ENABLE_NEW_CANCELLATION_VISA_MSG = IS_DEV

export const ENABLE_NEW_SIGNUP_ERRORS = IS_DEV

export const ENABLE_NEW_DATE_OF_BIRTH_FIELDS = IS_DEV

export const ENABLE_COMPLAINTS_CONDITION_UPDATES = IS_DEV

export const ENABLE_REFUNDS_CONDITION_UPDATES = IS_DEV

export const ENABLE_NEW_PROFILE_DESIGN = IS_DEV

export const ENABLE_NEW_RECRUITMENT = IS_DEV

export const ENABLE_NEW_COMPLAINT_RATING_REQUEST = IS_DEV

export const ENABLE_FIX_STARS_RATING = IS_DEV

export const ENABLE_TRIAL_WORN = IS_DEV

export const ENABLE_CHECK_USER_PROFILE = IS_DEV

export const ENABLE_NEW_ICONS = IS_DEV

export const newDesignKey = 'newFlow'

export let ENABLE_NEW_DESIGN_FLOW = IS_DEV && !!Number(localStorage.getItem(newDesignKey))

localStorage.setItem(newDesignKey, localStorage.getItem(newDesignKey) || '0')

export const ENABLE_VISA_PDF = IS_DEV

export const ENABLE_NEW_RECRUITMENT_BTN = IS_DEV

export const ENABLE_WAITING_INSURANCE_SELECTION_CANCEL_BTN = IS_DEV

export const ENABLE_REST_SURVEYS = IS_DEV

export const ENABLE_NATIONAL_ADDRESSES = false

export const ENABLE_ATTACHMENTS_CHECKBOX = IS_DEV

export const ENABLE_I_TO_I_CONTRACT_FIX = IS_DEV

export const ENABLE_GENDER_SELECTION_VISA = IS_DEV

export const ENABLE_COMPLAINTS_SURVEY = IS_DEV

export const ENABLE_COMPLAINTS_SURVEY_API_UPDATES = IS_DEV

export const ENABLE_LEASING_SERVICE = IS_DEV

export const ENABLE_REPORT_LABORER = IS_DEV

export const ENABLE_SUPPORT_TICKETS = IS_DEV

export const ENABLE_EXTRA_SERVICES = IS_DEV

export const ENABLE_LABORER_LANGUAGE_SWITCH = IS_DEV

export const ENABLE_NEW_LABORER_REQUESTS = IS_DEV

export const ENABLE_CANCELATION_WITHOUT_IBAN = IS_DEV

export const DISABLE_SERVICES = moment(new Date()).isBetween(
  moment('2024-09-13 13:45:00'),
  moment('2024-09-13 22:30:00'),
)

declare global {
  interface Window {
    enableNewDesign: (flag?: boolean) => void
  }
}

window.enableNewDesign = (flag) => {
  ENABLE_NEW_DESIGN_FLOW = typeof flag === 'boolean' ? flag : !!Number(localStorage.getItem(newDesignKey))
}

import { ServiceTypes } from 'common/types/commonTypes'
import { setRecruitmentFilter, RecruitmentFilterStateType } from 'redux/actionCreators/recruitmentFilter'
import Actions from 'redux/actions'

const initialState: RecruitmentFilterStateType = {
  typeOfRecruitment: ServiceTypes.NONE,
  occupation: undefined,
  nationality: undefined,
  priceSort: 'asc',
  ratingSort: 'desc',
  officeNameSearch: '',
  costRange: {
    min: undefined,
    max: undefined,
  },
  durationRange: {
    min: undefined,
    max: undefined,
  },
  arrivalCityId: undefined,
  religion: undefined,
  starsRating: 0,
  acceptanceRateRange: {
    min: undefined,
    max: undefined,
  },
  page: 0,
  clearPagesCount: false,
  extraPreferences: [],
}

const recruitmentFilter = (
  state: RecruitmentFilterStateType = initialState,
  action: ReturnType<typeof setRecruitmentFilter>,
): RecruitmentFilterStateType => {
  switch (action.type) {
    case Actions.SET_RECRUITMENT_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      }
    default:
      return state
  }
}

export default recruitmentFilter

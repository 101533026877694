import moment from 'moment'

import {
  AppendixFormBE,
  GetEtoINewRequestResponse,
  EtoIActionRequest,
  GetEtoILegacyRequestResponse,
} from 'api/newDLTransferAPI'
import { EToIAppendixStatus } from 'common/types/commonTypes'
import {
  formatOccupationsFromBE,
  formatNationalityFromBE,
  formatObjectWithTranslationFromBE,
} from 'common/utils/objectWithTranslation'

import { Nationality, ObjectWithTranslation } from '../../types/transformedRespTypes'
import { formatDateForUI, formatDateTimeForUIDashFormat } from '../common'

const DEFAULT_PROBATION_DAYS = 90

type Appendix = {
  id: number
  idNumber: number
  name: string
  status: EToIAppendixStatus
  nationality: ObjectWithTranslation
  newOccupation: ObjectWithTranslation
  salary: number
  startDate: string
  hasReplacementRequest: boolean
  transferredAt: string
  legalText: {
    id: number
    legalText: string
    startedAt: string | null
    type: string
  } | null
}

export type EToINewRequest = {
  isFinanciallyCapable: boolean
  isSalaryExcluded: boolean
  laborerSalaryForProbationPeriod: number
  probationDays: string
  id: number
  startedAt: string
  createdAt: string
  status: string
  duration: number
  laborerId: number
  laborerName: string
  laborerNewOccupation: ObjectWithTranslation
  laborerNationality: Nationality
  laborerBirthDate: string
  laborerPassportNumber: string
  laborerStatus: EToIAppendixStatus | null
  rejectionReasons: Array<ObjectWithTranslation>
  appendixes: Array<Appendix>
  replacementRequests: Array<EtoIActionRequest>
  cancellationRequests: Array<EtoIActionRequest>
  isLegacy: boolean
  issuedCancellationNoticeAt: string
  breakdown: {
    amount: number
    vat: number
    operationalCost: number
    salary: number
    salaryFor3Months: number
    serviceCost: number
  }
  establishment: {
    email: string
    crNumber: string
    licenceNumber: string
    vatNumber: string
    address: string
    mobile: string
    hrsdId: string
    city: {
      id: number
      label: string
      labelEn: string
    }
    logoUrl: string
    name: string
    laborOfficeId: string
    sequenceNumber: string
  }
  transferRequest: {
    rejectionReasons: Array<ObjectWithTranslation>
    status: string
    isTenDaysExceeded: boolean
  } | null
  employer: {
    id: number
    name: string
    birthDate: string
    mobile: string
    nationality: string
    address: string
    familySize: string
    houseTypeId: number
    children: string
    occupation: string
    secondaryMobile: string
    email: string
  }
  bill: {
    id: number
    createdAt: string
    paidAt: string
    paidAtDay: string
    paidAtHijri: string
  } | null
  financialDetails: {
    minBankBalance: number
    minSalary: number
  }
  shouldViewZatcaBill: boolean
  legalText: {
    id: number
    legalText: string
    startedAt: string | null
    type: string
  } | null
  governmentCost: number
  foreignRecruitmentCost: number
  vatPercentage: number
  amount: number
}

export const getEmptyEToINewRequest = (): EToINewRequest => ({
  isFinanciallyCapable: false,
  isSalaryExcluded: false,
  laborerSalaryForProbationPeriod: 0,
  probationDays: '',
  id: 0,
  duration: 0,
  startedAt: '',
  createdAt: '',
  status: '',
  laborerId: 0,
  laborerName: '',
  replacementRequests: [],
  cancellationRequests: [],
  isLegacy: false,
  issuedCancellationNoticeAt: '',
  laborerNewOccupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  laborerNationality: {
    id: 0,
    label: '',
    labelEn: '',
    abbreviation: '',
    isOnlineContractingAllowed: false,
    code: '',
  },
  laborerBirthDate: '',
  laborerPassportNumber: '',
  laborerStatus: null,
  rejectionReasons: [],
  appendixes: [],
  breakdown: {
    amount: 0,
    vat: 0,
    operationalCost: 0,
    salary: 0,
    salaryFor3Months: 0,
    serviceCost: 0,
  },
  establishment: {
    email: '',
    crNumber: '',
    licenceNumber: '',
    vatNumber: '',
    address: '',
    mobile: '',
    logoUrl: '',
    hrsdId: '',
    name: '',
    city: {
      id: 0,
      label: '',
      labelEn: '',
    },
    laborOfficeId: '',
    sequenceNumber: '',
  },
  employer: {
    id: 0,
    name: '',
    birthDate: '',
    mobile: '',
    nationality: '',
    address: '',
    familySize: '',
    houseTypeId: 0,
    children: '',
    occupation: '',
    secondaryMobile: '',
    email: '',
  },
  transferRequest: {
    rejectionReasons: [],
    status: '',
    isTenDaysExceeded: false,
  },
  bill: {
    id: 0,
    createdAt: '',
    paidAt: '',
    paidAtDay: '',
    paidAtHijri: '',
  },
  financialDetails: {
    minBankBalance: 0,
    minSalary: 0,
  },
  shouldViewZatcaBill: false,
  legalText: null,
  governmentCost: 0,
  foreignRecruitmentCost: 0,
  vatPercentage: 0,
  amount: 0,
})

export type EToILegacyRequest = {
  id: number
  laborerIqamaId: string
  currentSponsor: string
  createdAt: string
  status: string
  user: ObjectWithTranslation
  rejectionReasons: Array<ObjectWithTranslation>
  isLegacy: boolean
}

export const getEmptyEToILegacyRequest = () => ({
  id: 0,
  laborerIqamaId: '',
  currentSponsor: '',
  createdAt: '',
  status: '',
  user: {
    id: 0,
    label: '',
    labelEn: '',
  },
  rejectionReasons: [],
  isLegacy: false,
})

export const convertEToILegacyRequestFromResponse = (request: GetEtoILegacyRequestResponse): EToILegacyRequest => ({
  id: request.id,
  laborerIqamaId: request.laborer_iqama,
  user: {
    id: request.user.id,
    label: request.user.name,
    labelEn: request.user.name_en,
  },
  currentSponsor: request.current_sponsor,
  createdAt: request.created_at,
  status: request.status,
  rejectionReasons: request.rejectionReasons.map((reason) => formatObjectWithTranslationFromBE(reason)),
  isLegacy: request.is_legacy,
})

export type EToIRequest = EToILegacyRequest & EToINewRequest

const convertAppendixData = (resp: AppendixFormBE): Appendix => ({
  id: resp.id,
  idNumber: resp.id_number,
  name: resp.name,
  newOccupation: formatOccupationsFromBE(resp.new_occupation),
  nationality: formatNationalityFromBE(resp.nationality),
  salary: resp.salary,
  status: resp.status,
  startDate: resp.started_at,
  hasReplacementRequest: resp.has_replacement_request,
  transferredAt: '-',
  legalText: resp.legal_text
    ? {
        id: resp.legal_text.id,
        legalText: resp.legal_text.legal_text,
        startedAt: resp.legal_text.started_at,
        type: resp.legal_text.type,
      }
    : null,
})

const salaryMultiplyMapping = {
  30: 1,
  60: 2,
  90: 3,
}

export const convertEToINewRequestFromResponse = (request: GetEtoINewRequestResponse): EToINewRequest => {
  const salaryMappingKey = (request.probation_days || DEFAULT_PROBATION_DAYS) as keyof typeof salaryMultiplyMapping
  const transformedRequest: EToINewRequest = {
    probationDays: String(request.probation_days || DEFAULT_PROBATION_DAYS),
    isFinanciallyCapable: request.is_financially_capable,
    isSalaryExcluded: !!request.is_salary_excluded,
    laborerSalaryForProbationPeriod: request.laborer_salary * salaryMultiplyMapping[salaryMappingKey],
    id: request.id,
    startedAt: request.started_at || '',
    createdAt: request.created_at,
    status: request.status,
    laborerId: request.laborer_id_number,
    laborerName: request.laborer_name,
    laborerNewOccupation: formatOccupationsFromBE(request.laborer_new_occupation),
    laborerNationality: formatNationalityFromBE(request.laborer_nationality),
    laborerBirthDate: request.laborer_birth_date || '-',
    laborerPassportNumber: request.laborer_passport_number || '-',
    laborerStatus: request.laborer_status as EToIAppendixStatus,
    duration: request.elapsed_trial_period,
    isLegacy: request.is_legacy,
    issuedCancellationNoticeAt: request.issued_cancellation_notice_at || '',
    rejectionReasons: [
      ...(request.rejection_reasons || []),
      ...(request.transfer_request?.rejection_reasons || []),
    ].map((reason) => formatObjectWithTranslationFromBE(reason)),
    transferRequest: request.transfer_request
      ? {
          rejectionReasons: (request.transfer_request?.rejection_reasons || []).map((reason) =>
            formatObjectWithTranslationFromBE(reason),
          ),
          status: request.transfer_request?.status,
          isTenDaysExceeded: request.transfer_request.is_10_days_exceeded,
        }
      : null,
    breakdown: {
      amount: request.amount,
      vat: request.vat,
      operationalCost: request.operational_cost,
      salary: request.laborer_salary,
      salaryFor3Months: request.laborer_salary_for_3_months,
      serviceCost: request.service_cost,
    },
    bill: request.bill
      ? {
          id: request.bill?.id || 0,
          createdAt: formatDateTimeForUIDashFormat(request.bill?.created_at || ''),
          paidAt: formatDateTimeForUIDashFormat(request.bill?.paid_at || ''),
          paidAtDay: request.bill?.paid_at_day || '',
          paidAtHijri: formatDateTimeForUIDashFormat(request.bill?.paid_at_hijri || ''),
        }
      : null,
    replacementRequests: request.replacement_requests?.map((item) => ({
      id: item.id,
      status: item.status,
    })),
    cancellationRequests: request.cancellation_requests?.map((item) => ({
      id: item.id,
      status: item.status,
    })),
    establishment: {
      email: request.establishment.email,
      mobile: request.establishment.mobile,
      logoUrl: request.establishment.logoUrl || '',
      name: request.establishment.name,
      hrsdId: request.establishment.hrsdId,
      crNumber: request.establishment.crNumber,
      licenceNumber: request.establishment.licenceNumber,
      vatNumber: request.establishment.vatNumber,
      address: `${request.establishment.address.country} ${request.establishment.address.city} ${request.establishment.address.district} ${request.establishment.address.street} ${request.establishment.address.postalCode}`,
      city: {
        id: parseInt(request.establishment.city.id),
        label: request.establishment.city.name_ar,
        labelEn: request.establishment.city.name_en,
      },
      laborOfficeId: request.establishment.laborOfficeId,
      sequenceNumber: request.establishment.sequenceNumber,
    },
    employer: {
      id: request.employer.id_number,
      name: request.employer.name,
      birthDate: request.employer.nic.birth_date,
      mobile: request.employer.mobile,
      nationality: request.employer.nic.nationality,
      address: request.employer.personal_details.address,
      houseTypeId: parseInt(request.employer.personal_details.house_type_id),
      familySize: request.employer.personal_details.family_size,
      children: request.employer.personal_details.children_under_twelve_count,
      occupation: request.employer.nic.occupation,
      secondaryMobile: request.employer.secondary_mobile,
      email: request.employer.email,
    },
    financialDetails: {
      minBankBalance: request.financial_details?.min_bank_balance || 0,
      minSalary: request.financial_details?.min_salary || 0,
    },
    appendixes: [],
    shouldViewZatcaBill: request.should_view_zatca_bill,
    legalText: request.legal_text
      ? {
          id: request.legal_text.id,
          legalText: request.legal_text.legal_text,
          startedAt: request.legal_text.started_at,
          type: request.legal_text.type,
        }
      : null,
    governmentCost: request.government_cost || 0,
    foreignRecruitmentCost: request.foreign_recruitment_cost || 0,
    vatPercentage: request.vat_percentage || 0,
    amount: request.amount || 0,
  }

  const firstLaborerAppendix: Appendix = {
    id: 0,
    idNumber: transformedRequest.laborerId,
    name: transformedRequest.laborerName,
    newOccupation: transformedRequest.laborerNewOccupation,
    nationality: transformedRequest.laborerNationality,
    salary: transformedRequest.breakdown.salary,
    status: request.laborer_status,
    startDate: request.laborer_started_at || '',
    hasReplacementRequest: request.laborer_has_replacement_request,
    transferredAt: '-',
    legalText: transformedRequest.legalText || null,
  }

  const appendixes = request.appendixes.map(convertAppendixData)

  if (appendixes.length) {
    const latestAppendix = appendixes[0]
    latestAppendix.transferredAt = request.transferred_at
      ? formatDateForUI(request.transferred_at)
      : formatDateForUI(
          request.probation_days
            ? request.will_end_at
            : moment(latestAppendix.startDate).add(DEFAULT_PROBATION_DAYS, 'days'),
        )
  }

  transformedRequest.appendixes = appendixes.concat([firstLaborerAppendix])

  return transformedRequest
}

export const convertEToIRequestFromResponse = (
  request: GetEtoILegacyRequestResponse | GetEtoINewRequestResponse,
): EToIRequest => {
  if (request.is_legacy) {
    return Object.assign(
      getEmptyEToINewRequest(),
      convertEToILegacyRequestFromResponse(request as GetEtoILegacyRequestResponse),
    )
  }
  return Object.assign(
    getEmptyEToILegacyRequest(),
    convertEToINewRequestFromResponse(request as GetEtoINewRequestResponse),
  )
}

import { GetMarketOffersParams } from 'api/prosAPI'
import { ServiceTypes } from 'common/types/commonTypes'

import Actions from '../actions'

export type RecruitmentFilterStateType = Omit<GetMarketOffersParams, 'typeOfRecruitment'> & {
  typeOfRecruitment: ServiceTypes
}

type FilterValues = Partial<RecruitmentFilterStateType>

export function setRecruitmentFilter(filter: FilterValues) {
  return {
    type: Actions.SET_RECRUITMENT_FILTER,
    payload: {
      filter: {
        page: 0,
        clearPagesCount: true,
        ...filter,
      },
    },
  }
}

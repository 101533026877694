import moment from 'moment'

import { ENABLE_COMPLAINTS_CHANGES, GOOGLE_API_KEY, IS_DEV, IS_PROD } from './envConstants'
import {
  ValueLabelOption,
  ServiceTypes,
  ReligionTypes,
  ContractComplaintCategory,
  InstallmentKeysType,
  Lang,
  NationalityTranslationType,
} from './types/commonTypes'

declare global {
  interface Window {
    dataLayer: Array<unknown>
    gtag: (...rest: Array<unknown>) => void
    newrelic: { setCustomAttribute: (name: string, value: string | number) => void }
    ApplePaySession: typeof ApplePaySession
  }
}

const GTAG_ID = 'G-8556VBXDLK'
const isEng = localStorage.getItem('language') === Lang.en

export const EVISA_AUTH_HEADER = 'x-auth-token'

export const MUSANED_SECURITY_HEADER_VALUE = 't76GjY19tKTm'

const LABIBA_STORY_ID_DEV = isEng ? 'e138a861-26fa-4ae2-a109-f39959ec2e76' : 'd62a059e-ae55-40e0-965c-5fb7d3a9b201'
const LABIBA_STORY_ID_PROD = isEng ? '2f3da3d6-1eb5-45f1-b318-eafe9875e44b' : '90c90647-d734-4609-bba2-6a7db085c55a'

export const EXTERNAL_SCRIPTS: Array<{
  attributes?: Record<string, string | boolean | number>
  init?: () => void
  innerHTML?: string
}> = [
  // We also have New relic as external script but it should be inlined in the html, since it's monkey patching browser APIs
  {
    attributes: {
      async: true,
      src: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&region=SA`,
    },
  },
  {
    attributes: {
      async: true,
      src: IS_DEV ? 'https://botbuilder.labiba.ai/static/js/bundle.js' : 'https://cdn.labiba.ai/static/js/bundle.js',
      id: 'labibaWebChat',
      storyId: IS_DEV ? LABIBA_STORY_ID_DEV : LABIBA_STORY_ID_PROD,
      baseURL: 'https://botbuilder.labiba.ai',
      chatlocation: isEng ? 'left' : 'right',
    },
  },
  {
    attributes: {
      async: true,
      src: 'https://cdn.userway.org/widget.js',
      'data-account': 'HwgPgsghav',
    },
  },
  ...(IS_PROD
    ? [
        // { attributes: { async: true, src: 'https://script.crazyegg.com/pages/scripts/0074/7386.js' } },
        {
          attributes: { async: true, src: `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}` },
          init: () => {
            window.dataLayer = window.dataLayer || []
            // eslint-disable-next-line func-names
            window.gtag = function () {
              // eslint-disable-next-line prefer-rest-params
              window.dataLayer.push(arguments)
            }
            window.gtag('js', new Date())
            window.gtag('config', GTAG_ID)
          },
        },
        {
          innerHTML: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2744204,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        },
      ]
    : []),
]

export const ENTER_KEY = 'Enter'
export const ESCAPE_KEY = 'Escape'
export const BACKSPACE = 'Backspace'

export const SORT_ASC = 'asc'
export const SORT_DESC = 'desc'

export const DEFAULT_DEBOUNCE_TIME = 100

export const MAX_SELECTED_OFFERS_COUNT = 3

export const RECAPTCHA_ERROR_CODE = 110
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'

export const TRANSITION_TIMEOUT = 300
export const TRANSITION_TIMEOUT_SMOOTH = TRANSITION_TIMEOUT * 3
export const RESEND_SMS_COUNTER_TIME = 119000

export const MARKETPLACE_OFFERS_CARDS_PER_PAGE = 12

export const TABLET_LARGE = 1366
export const PHONE_LARGE = 768
export const DASHBOARD_AMOUNT_PER_PAGE = 3

export const MIN_SALARY = 500
export const MAX_SALARY = 30000

export const MIN_AUTH_CONTRACT_DURATION = 1
export const MAX_AUTH_CONTRACT_DURATION = 24

export const GOLD_RATE_MIN = 80
export const SILVER_RATE_MIN = 50

export const MIN_TOTAL_AMOUNT_FOR_INSTALLMENT_PLAN = 1000

export const INSURANCE_ADMINISTRATION_FEE = 50

export const ELIGIBLE_FOR_CANCELATION_WITHOUT_IBAN = 150

export const DATE_REG_EXP = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

export const MONTH_YEAR_DATE_REG_EXP = /^([12]\d{3})-(0[1-9]|1[0-2])$/

export const AGE_PREFERENCES: Map<number, ValueLabelOption<number>> = new Map([
  [1, { labelKey: 'ageRangeFromEighteen', value: 1 }],
  [2, { labelKey: 'ageRangeFromThirtyOne', value: 2 }],
  [3, { labelKey: 'ageRangeFromFortyOne', value: 3 }],
  [4, { labelKey: 'ageRangeFromFiftyOne', value: 4 }],
  [5, { labelKey: 'ageRangeFromSixtyOne', value: 5 }],
])

export const AGE_PREFERENCES_INTERVIEW: Array<ValueLabelOption<string>> = [
  { labelKey: 'ageRangeFromEighteen', value: '21-30' },
  { labelKey: 'ageRangeFromThirtyOne', value: '31-40' },
  { labelKey: 'ageRangeFromFortyOne', value: '41-50' },
  { labelKey: 'ageRangeFromFiftyOne', value: '51-60' },
  { labelKey: 'ageRangeFromSixtyOne', value: '61-65' },
]

export const EXPERIENCE_PREFERENCES: Map<number, ValueLabelOption<number>> = new Map([
  [1, { labelKey: 'previouslyWorkedInSaudiArabia', value: 1 }],
  [2, { labelKey: 'previouslyWorkedInGulfCountry', value: 2 }],
  [3, { labelKey: 'hasPreviouslyWorked', value: 3 }],
  [4, { labelKey: 'hasNeverWorked', value: 4 }],
  [5, { labelKey: 'notSpecified', value: 5 }],
])

export const REPORT_OPTIONS = [
  { value: 'image_not_clear', labelKey: 'imageNotClear' },
  { value: 'criteria_not_matching', labelKey: 'criteriaNotMatching' },
  { value: 'wrong_file', labelKey: 'wrongFile' },
]

export const AGE_RANGE = [
  { labelKey: 'ageRangeFromEighteen', value: 'RANGE_21_30' },
  { labelKey: 'ageRangeFromThirtyOne', value: 'RANGE_31_40' },
  { labelKey: 'ageRangeFromFortyOne', value: 'RANGE_41_50' },
  { labelKey: 'ageRangeFromFiftyOne', value: 'RANGE_51_60' },
  { labelKey: 'ageRangeFromSixtyOne', value: 'RANGE_61_65' },
]

export const YEARS_OF_EXPERIENCE: Map<number, ValueLabelOption<number>> = new Map([
  [1, { labelKey: 'neverWorked', value: 1 }],
  [2, { labelKey: 'twoYears', value: 2 }],
  [3, { labelKey: 'fiveYears', value: 5 }],
  [4, { labelKey: 'tenYears', value: 10 }],
  [5, { labelKey: 'fifteenTears', value: 15 }],
])

export const CONTRACT_DURATION: Map<number, ValueLabelOption<number>> = new Map([
  [1, { labelKey: 'oneMonth', value: 1 }],
  [2, { labelKey: 'threMonths', value: 3 }],
  [3, { labelKey: 'sixMonths', value: 6 }],
  [4, { labelKey: 'twelveMonths', value: 12 }],
  [5, { labelKey: 'eighteenMonths', value: 18 }],
  [6, { labelKey: 'twentyFourMonths', value: 24 }],
])

export const NATIONALITIES_ELIGIBLE_FOR_MAAROFA = ['ph', 'ng', 'lk', 'ug', 'ke']

export const RELIGION_OPTIONS: Map<ReligionTypes, ValueLabelOption<ReligionTypes>> = new Map([
  [
    ReligionTypes.MUSLIM,
    {
      labelKey: `religions.${ReligionTypes.MUSLIM}`,
      value: ReligionTypes.MUSLIM,
    },
  ],
  [
    ReligionTypes.NOT_MUSLIM,
    {
      labelKey: `religions.${ReligionTypes.NOT_MUSLIM}`,
      value: ReligionTypes.NOT_MUSLIM,
    },
  ],
])

export const SERVICE_TYPE_OPTIONS: Map<ServiceTypes, ValueLabelOption<ServiceTypes>> = new Map([
  [
    ServiceTypes.RECRUITMENT,
    {
      labelKey: 'recruitment',
      value: ServiceTypes.RECRUITMENT,
    },
  ],
  [
    ServiceTypes.PRESELECTED,
    {
      labelKey: 'preselected',
      value: ServiceTypes.PRESELECTED,
    },
  ],
])

export const DEFAULT_ARRIVAL_CITY_ID = 3 // Riyadh id

export const LOADING = {
  isLoading: true,
  success: false,
  fail: false,
}

export const SUCCESS = {
  isLoading: false,
  success: true,
  fail: false,
}

export const FAIL = {
  isLoading: false,
  success: false,
  fail: true,
}

export const CV_PLACEHOLDERS_COUNT = 5

export const LIST_ITEMS_PER_PAGE = 4

export const END_USER = 'end-user'

export const MAX_AMOUNT_OF_DAYS_FOR_CONTRACT_EXTENSION = 210

export const START_COVID_POLICY_DATE = moment('2019-10-17')
export const END_COVID_POLICY_DATE = moment('2020-03-18')

export const COVID_NEW_TERMS_DATE = moment('2020-10-07')

export const CANCELLATION_POLICY_DATE = moment('2022-12-13 07:00:00')
export const NEW_CANCELLATION_POLICY_DATE = moment('2023-03-03 07:00:00')

export const COVID_NEW_POLICY_START = moment('2020-12-20')
export const COVID_NEW_POLICY_END = moment('2021-05-20')

export const ZATCA_INVOICE_START_DATE = moment('2024-01-01')

export const CONTRACT_MIN_DATE_FOR_COMPLAINING = moment('2019-07-01')
export const CONTRACT_MIN_AMOUNT_OF_DAYS_FOR_COMPLAINING = 120
export const CONTRACT_MIN_AMOUNT_OF_DAYS_FOR_DIRECT_CONTRACTS = 90

export const START_PHILIPPINES_CANCEL_EXEMPTION = moment('2021-11-15')
export const END_PHILIPPINES_CANCEL_EXEMPTION = moment('2021-12-09')

export const NEW_COMPLAINTS_DATE = moment('2023-01-01 00:00:00')

export const NEW_CONTRACT_CANCELATION_POLICIES = IS_PROD
  ? moment('2023-11-16 00:00:00')
  : moment('2023-11-01 00:00:00')

export const NEW_CONTRACT_CANCELATION_POLICIES_ADDITIONAL = IS_PROD
  ? moment('2023-11-21 00:00:00')
  : moment('2023-11-01 00:00:00')

export const DAYS_FOR_REFUND_TICKET_CREATION = 5

export const ADDITIONAL_PREFERENCES_SEPARATOR = '^additionalPreferences^'

export const NATIONALITY_WITH_ADDITIONAL_TERMS_IDS = new Set<number>([2, 7, 15, 9, 91, 4])

export const NATIONALITY_WITH_ADDITIONAL_TERMS = new Set<string>([
  'Filipino',
  'Bangladeshi',
  'Uganda',
  'Sri Lankan',
  'Kenya',
  'Indian',
])

export const COMPLAINT_TICKET_OTHER_CATEGORY = '13610'
export const MISSING_ATTACHMENT_PREFIX = '_14700_'
export const COMPLAINT_TICKET_PRO_ACTION_CATEGORIES = ['14005', '14006']
export const COMPLAINT_PRO_FILE_PREFIX = ['_13401_', '_13200_', '_13400_']

export const NEW_GENERAL_PROVISIONS_DATE = moment('2023-05-12')

export const ACCOMMODATION_TYPES: Map<number, ValueLabelOption<number>> = new Map([
  [1, { labelKey: 'accomodationTypes.villa', value: 1 }],
  [2, { labelKey: 'accomodationTypes.apartment', value: 2 }],
])

export const MALE_AR = 'ذكر'
export const USED_VISA_ERROR = 'عذراً، لا يمكنك الغاء التأشيرة لارتباطها بعقد'

export const MINIMUM_VISA_COUNT_FOR_ALTERNATIVE = 1

export const PREF_PRES_NEW_CONTRACT_DATE = moment('2023-11-21')

export const ADDONS_INVOICE_START_DATE = moment('2023-07-23')

export const ZATCA_CONTRACT_INVOICE_START_DATE = moment('2024-01-01')

export const INCLUDE_INSURANCE_DATE = moment('2023-11-15')

export const MAAROFA_ALLOWED_NATIONALITY_IDS = [2, 9, 15, 91]

// do not change order since COMPLAINT_ATTACHMENTS depends on it
export const COMPLAINT_CATEGORIES: Array<ContractComplaintCategory> = ENABLE_COMPLAINTS_CHANGES
  ? [
      {
        id: 13600,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true, // true
        selectableWindow: 90,
        selectableForValidContracts: true, // false
        selectableForCanceledContracts: false, // true
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 13601,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: false,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: true,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13602,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 60,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13603,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 60,
        selectableForValidContracts: false,
        selectableForCanceledContracts: true,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13604,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13605,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13606,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13607,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13608,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13609,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 14124,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 0,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 17201,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
    ]
  : [
      {
        id: 13600,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: false, // true
        selectableWindow: 0,
        selectableForValidContracts: true, // false
        selectableForCanceledContracts: false, // true
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 13601,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: false,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: true,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13602,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 60,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13603,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 60,
        selectableForValidContracts: false,
        selectableForCanceledContracts: true,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13604,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13605,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13606,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13607,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: true,
        selectableForRecruitment: true,
        selectableForAllCases: false,
      },
      {
        id: 13608,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 13609,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 14124,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 90,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
      {
        id: 17201,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 150,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: false,
      },
    ]

export const WORKER_DID_NOT_ARRIVED_CATEGORY_ID = 14124

export const COMPLAINT_DIRECT_CONTRACT_CATEGORIES: Array<ContractComplaintCategory> = ENABLE_COMPLAINTS_CHANGES
  ? [
      {
        id: 13600,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: false,
        selectableWindow: 0,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 14124,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 0,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 18500,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 90,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
    ]
  : [
      {
        id: 13600,
        timeRestricted: false,
        selectableStartingAtIncludesDuration: false,
        selectableWindow: 0,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 14124,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 90,
        selectableForValidContracts: false,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
      {
        id: 18500,
        timeRestricted: true,
        selectableStartingAtIncludesDuration: true,
        selectableWindow: 90,
        selectableForValidContracts: true,
        selectableForCanceledContracts: false,
        selectableForNonCompensation: false,
        selectableForRecruitment: false,
        selectableForAllCases: true,
      },
    ]

export const COMPLAINT_ATTACHMENTS = new Map<number, string>([
  [COMPLAINT_CATEGORIES[4].id, 'medicalReport'],
  [COMPLAINT_CATEGORIES[6].id, 'labourerEscape'],
  [COMPLAINT_CATEGORIES[7].id, 'labourerAbsence'],
  [COMPLAINT_CATEGORIES[11].id, 'evidenceOrStatus'],
])

export const DEFAULT_VISA_COST = 150

export const GOOGLE_MAP_URL = 'https://www.google.com/maps/search/?api=1&query='

export const MADA_BINS = [
  '588845',
  '440647',
  '440795',
  '446404',
  '457865',
  '968208',
  '636120',
  '417633',
  '468540',
  '468541',
  '468542',
  '468543',
  '968201',
  '446393',
  '409201',
  '458456',
  '484783',
  '968205',
  '462220',
  '455708',
  '588847',
  '588848',
  '455036',
  '968203',
  '486094',
  '486095',
  '486096',
  '504300',
  '440533',
  '489318',
  '489319',
  '445564',
  '968211',
  '410685',
  '406996',
  '432328',
  '428671',
  '428672',
  '428673',
  '968206',
  '446672',
  '543357',
  '434107',
  '407197',
  '407395',
  '412565',
  '431361',
  '604906',
  '521076',
  '588850',
  '968202',
  '529415',
  '535825',
  '543085',
  '524130',
  '554180',
  '549760',
  '968209',
  '524514',
  '529741',
  '537767',
  '535989',
  '536023',
  '513213',
  '520058',
  '585265',
  '588983',
  '588982',
  '589005',
  '508160',
  '531095',
  '530906',
  '532013',
  '605141',
  '968204',
  '422817',
  '422818',
  '422819',
  '428331',
  '483010',
  '483011',
  '483012',
  '589206',
  '968207',
  '419593',
  '439954',
  '530060',
  '531196',
  '457997',
  '474491',
  '410621',
  '558563',
  '420132',
]

export const IS_VISIBLE_HOLIDAY_NOTIFICATION = false
export const IS_VISIBLE_HOLIDAY_MODAL = false

export const INSTALLMENT_KEYS = new Set<InstallmentKeysType>([
  'three_months_installments_fees',
  'six_months_installments_fees',
  'twelve_months_installments_fees',
])

export const QUARANTINE_TERMS_START = moment('2021-06-17T08:59:59.000Z')
export const QUARANTINE_TERMS_END = moment('2022-03-17T06:00:00.000Z')

export const HIDE_DONATION = 'hideDonation'

export const HIDE_WELCOMING_BAR = 'hideWelcomingBar'

export const HIDE_WELCOMING_POP_UP = 'hideWelcomingPopUp'

export const HIDE_DELAYED_CONTRACTS_POP_UP = 'hideDelayedContractsPopUp'

export const COOKIES_ACCEPTED_KEY = 'cookiesAccepted'

export const HIDE_HOME_KABI_SERVICE_BAR = 'hideHomeKabiServiceBar'

export const HIDE_MAAROFA_KABI_SERVICE_BAR = 'hideMaarofaKabiServiceBar'

export const DEFAULT_VAT_VALUE = '7.5'

export const PHILIPPINES_ID = 2

export const KENYA_ID = 91

export const MAX_FILES_FOR_COMPLAINT = 3

export const SOCIAL_ACCOUNT = '@Musaned_DL'

export const CONTACT_PHONE_NUMBER = '920002866'

export const CONTACT_EMAIL_ADDRESS = 'care.e@musaned.gov.sa'

export const SUPPORT_EMAIL_ADDRESS = 'Musaned@support.com'

export const VERIFIED = 'mobileVerified'

export const IBAN_CHECKED = 'ibanChecked'

export const NAJM_LINK = 'https://dli.najm.sa/consumer'

export const SPLONLINE_EN_LINK = 'https://splonline.com.sa/en/national-address-1'

export const SPLONLINE_AR_LINK = 'https://splonline.com.sa/ar/national-address-1'

export const SAMA_LINK =
  'https://www.sama.gov.sa/ar-sa/RulesInstructions/InsuranceRulesAndRegulations/The_Standard_Insurance_Policy_on_Domestic_Workers_Contract.pdf'

export const SAMA_COMPLAINT_LINK = 'https://www.sama.gov.sa/ar-sa/Pages/default.aspx'

export const MEWA_LINK = 'https://www.mewa.gov.sa'

export const HRSD_AR_LINK = 'https://hrsd.gov.sa/ar/page/سياسة-الخصوصية'

export const HRSD_EN_LINK = 'https://hrsd.gov.sa/en/page/privacy-policy'

export const RAQMI_LINK = 'http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/436'

export const FULL_INVOICE_EDGE_PRICE = 1000 // Riyal

export const MAX_FILES_FOR_ATTACHMENTS = 3

export const DEFAULT_FONT_SIZE = 16

export const ISTIQDAM_LINK = 'https://dynamic.istiqdam.sa/packages?sort=asc&page=1'

export const CONTRACT_DRAFT_DATE = moment('2023-09-19')

export const DGA_LINK =
  'https://raqmi.dga.gov.sa/DXSurvey/survey/p/steps/1/cb5ba75b-a833-48dd-2ad6-08dc36cb7be5/register'

export const INSURANCE_POLICY_LINK = `${
  IS_DEV ? 'https://frontend.musaned.tamkeen.live' : 'https://musaned.com.sa'
}/The_Standard_Insurance_Policy_on_Domestic_Workers_Contract.pdf`

export const NATIONALITY_TRANSLATIONS: Array<NationalityTranslationType> = [
  {
    nationality: 'العربية السعودية',
    label: 'المملكة العربية السعودية',
    labelEn: 'Saudi Arabia',
  },
  {
    nationality: 'الامارات  العربية',
    label: 'الإمارات العربية المتحدة',
    labelEn: 'United Arab Emirates',
  },
  {
    nationality: 'اثيوبيا',
    label: 'اثيوبيا',
    labelEn: 'Ethiopia',
  },
  {
    nationality: 'اذربيجان',
    label: 'اذربيجان',
    labelEn: 'Azerbaijan',
  },
  {
    nationality: 'ارتيريا',
    label: 'ارتيريا',
    labelEn: 'Eritrea',
  },
  {
    nationality: 'ارمينيا',
    label: 'ارمينيا',
    labelEn: 'Armenia',
  },
  {
    nationality: 'ازبكستان',
    label: 'ازبكستان',
    labelEn: 'Uzbekistan',
  },
  {
    nationality: 'اسبانيا',
    label: 'اسبانيا',
    labelEn: 'Spain',
  },
  {
    nationality: 'استراليا',
    label: 'استراليا',
    labelEn: 'Australia',
  },
  {
    nationality: 'افغانستان',
    label: 'افغانستان',
    labelEn: 'Afghanistan',
  },
  {
    nationality: 'الارجنتين',
    label: 'الارجنتين',
    labelEn: 'Argentina',
  },
  {
    nationality: 'الاردن',
    label: 'الاردن',
    labelEn: 'Jordan',
  },
  {
    nationality: 'البانيا',
    label: 'البانيا',
    labelEn: 'Albania',
  },
  {
    nationality: 'البحرين',
    label: 'البحرين',
    labelEn: 'Bahrain',
  },
  {
    nationality: 'البرازيل',
    label: 'البرازيل',
    labelEn: 'Brazil',
  },
  {
    nationality: 'البرازيل',
    label: 'البرازيل',
    labelEn: 'Brazil',
  },
  {
    nationality: 'البرتغال',
    label: 'البرتغال',
    labelEn: 'Portugal',
  },
  {
    nationality: 'الجزائر',
    label: 'الجزائر',
    labelEn: 'Algeria',
  },
  {
    nationality: 'الدانمارك',
    label: 'الدانمارك',
    labelEn: 'Denmark',
  },
  {
    nationality: 'السنغال',
    label: 'السنغال',
    labelEn: 'Senegal',
  },
  {
    nationality: 'السودان',
    label: 'السودان',
    labelEn: 'Sudan',
  },
  {
    nationality: 'السويد',
    label: 'السويد',
    labelEn: 'Sweden',
  },
  {
    nationality: 'الصومال',
    label: 'الصومال',
    labelEn: 'Somalia',
  },
  {
    nationality: 'العراق',
    label: 'العراق',
    labelEn: 'Iraq',
  },
  {
    nationality: 'الفلبين',
    label: 'الفلبين',
    labelEn: 'Philippines',
  },
  {
    nationality: 'الكاميرون',
    label: 'الكاميرون',
    labelEn: 'Cameroon',
  },
  {
    nationality: 'الكويت',
    label: 'الكويت',
    labelEn: 'Kuwait',
  },
  {
    nationality: 'المانيا',
    label: 'المانيا',
    labelEn: 'Germany',
  },
  {
    nationality: 'المغرب',
    label: 'المغرب',
    labelEn: 'Morocco',
  },
  {
    nationality: 'المكسيك',
    label: 'المكسيك',
    labelEn: 'Mexico',
  },
  {
    nationality: 'المملكة المتحدة',
    label: 'المملكة المتحدة',
    labelEn: 'United kingdom',
  },
  {
    nationality: 'النرويج',
    label: 'النرويج',
    labelEn: 'Norway',
  },
  {
    nationality: 'النمسا',
    label: 'النمسا',
    labelEn: 'Austria',
  },
  {
    nationality: 'النيجر',
    label: 'النيجر',
    labelEn: 'Niger',
  },
  {
    nationality: 'الهند',
    label: 'الهند',
    labelEn: 'India',
  },
  {
    nationality: 'الولايات  المتحدة',
    label: 'الولايات  المتحدة',
    labelEn: 'United State',
  },
  {
    nationality: 'اليابان',
    label: 'اليابان',
    labelEn: 'Japan',
  },
  {
    nationality: 'اليمن',
    label: 'اليمن',
    labelEn: 'Yemen',
  },
  {
    nationality: 'اليونان',
    label: 'اليونان',
    labelEn: 'Greece',
  },
  {
    nationality: 'اندونيسيا',
    label: 'اندونيسيا',
    labelEn: 'Indonesia',
  },
  {
    nationality: 'اوغندة',
    label: 'اوغندة',
    labelEn: 'Uganda',
  },
  {
    nationality: 'اوكرانيا',
    label: 'اوكرانيا',
    labelEn: 'Ukraine',
  },
  {
    nationality: 'ايران',
    label: 'ايران',
    labelEn: 'Iran',
  },
  {
    nationality: 'ايرلندا',
    label: 'ايرلندا',
    labelEn: 'Ireland',
  },
  {
    nationality: 'ايطاليا',
    label: 'ايطاليا',
    labelEn: 'Italy',
  },
  {
    nationality: 'باربودا',
    label: 'باربودا',
    labelEn: 'Barbuda',
  },
  {
    nationality: 'باكستان',
    label: 'باكستان',
    labelEn: 'Pakistan',
  },
  {
    nationality: 'بلجيكا',
    label: 'بلجيكا',
    labelEn: 'Belgium',
  },
  {
    nationality: 'بلغاريا',
    label: 'بلغاريا',
    labelEn: 'Bulgaria',
  },
  {
    nationality: 'بنجلاديش',
    label: 'بنجلاديش',
    labelEn: 'Bangladesh',
  },
  {
    nationality: 'بنما',
    label: 'بنما',
    labelEn: 'Panama',
  },
  {
    nationality: 'بوركينافاسو',
    label: 'بوركينافاسو',
    labelEn: 'Burkina Faso',
  },
  {
    nationality: 'بولندا',
    label: 'بولندا',
    labelEn: 'Poland',
  },
  {
    nationality: 'بيرو',
    label: 'بيليز',
    labelEn: 'Peru',
  },
  {
    nationality: 'بيليز',
    label: 'بيليز',
    labelEn: 'Belize',
  },
  {
    nationality: 'تايلند',
    label: 'تايلند',
    labelEn: 'Thailand',
  },
  {
    nationality: 'تركيا',
    label: 'تركيا',
    labelEn: 'Turkey',
  },
  {
    nationality: 'تشاد',
    label: 'تشاد',
    labelEn: 'Chad',
  },
  {
    nationality: 'تشيك',
    label: 'تشيك',
    labelEn: 'Cech',
  },
  {
    nationality: 'تنزانيا',
    label: 'تنزانيا',
    labelEn: 'Tanzania',
  },
  {
    nationality: 'تونس',
    label: 'تونس',
    labelEn: 'Tunisia',
  },
  {
    nationality: 'جزر ملاديف',
    label: 'جزر ملاديف',
    labelEn: 'Maldives',
  },
  {
    nationality: 'جمهورية دمينكان',
    label: 'جمهورية دمينكان',
    labelEn: 'Dominican Republic',
  },
  {
    nationality: 'جمهورية ميانمار',
    label: 'جمهورية ميانمار',
    labelEn: 'Republic of Myanmar',
  },
  {
    nationality: 'جنوب  افريقيا',
    label: 'جنوب  افريقيا',
    labelEn: 'South Africa',
  },
  {
    nationality: 'جورجيا',
    label: 'جورجيا',
    labelEn: 'Georgia',
  },
  {
    nationality: 'جيبوتى',
    label: 'جيبوتى',
    labelEn: 'Djibouti',
  },
  {
    nationality: 'دومينيكا',
    label: 'دومينيكا',
    labelEn: 'Dominica',
  },
  {
    nationality: 'روسيا الاتحادية',
    label: 'روسيا الاتحادية',
    labelEn: 'United Russia',
  },
  {
    nationality: 'رومانيا',
    label: 'رومانيا',
    labelEn: 'Romania',
  },
  {
    nationality: 'زمبابوي',
    label: 'زمبابوي',
    labelEn: 'Zimbabwe',
  },
  {
    nationality: 'ساحل العاج',
    label: 'ساحل العاج',
    labelEn: 'Ivory Coast',
  },
  {
    nationality: 'سانت كيتس  ونافيس',
    label: 'سانت كيتس  ونافيس',
    labelEn: 'Saint Kitts and Nevis',
  },
  {
    nationality: 'سري لنكا',
    label: 'سري لنكا',
    labelEn: 'Sri Lanka',
  },
  {
    nationality: 'سلوفاكيا',
    label: 'سلوفاكيا',
    labelEn: 'Slovakia',
  },
  {
    nationality: 'سنغافورة',
    label: 'سنغافورة',
    labelEn: 'Singapore',
  },
  {
    nationality: 'سوريا',
    label: 'سوريا',
    labelEn: 'Syrian Arab Republic',
  },
  {
    nationality: 'سويسرا',
    label: 'سويسرا',
    labelEn: 'Switzerland',
  },
  {
    nationality: 'شيلي',
    label: 'شيلي',
    labelEn: 'Chilean',
  },
  {
    nationality: 'طاجكستان',
    label: 'طاجكستان',
    labelEn: 'Tajikistan',
  },
  {
    nationality: 'عمان',
    label: 'عمان',
    labelEn: 'Amman',
  },
  {
    nationality: 'غامبيا',
    label: 'غامبيا',
    labelEn: 'Gambia',
  },
  {
    nationality: 'غانا',
    label: 'غانا',
    labelEn: 'Ghana',
  },
  {
    nationality: 'غينيا',
    label: 'غينيا',
    labelEn: 'Guinea',
  },
  {
    nationality: 'غينيابيساو',
    label: 'غينيابيساو',
    labelEn: 'Guinea-Bissau',
  },
  {
    nationality: 'فرنسا',
    label: 'فرنسا',
    labelEn: 'France',
  },
  {
    nationality: 'فلسطين',
    label: 'فلسطين',
    labelEn: 'Palestine',
  },
  {
    nationality: 'فنزويلا',
    label: 'فنزويلا',
    labelEn: 'Venezuela',
  },
  {
    nationality: 'فنلندا',
    label: 'فنلندا',
    labelEn: 'Finland',
  },
  {
    nationality: 'قبرص',
    label: 'قبرص',
    labelEn: 'Cyprus',
  },
  {
    nationality: 'قرغيزستان',
    label: 'قرغيزستان',
    labelEn: 'Kyrgyzstan',
  },
  {
    nationality: 'قطر',
    label: 'قطر',
    labelEn: 'Qatar',
  },
  {
    nationality: 'كازاخستان',
    label: 'كازاخستان',
    labelEn: 'Kazakhstan',
  },
  {
    nationality: 'كرواتيا',
    label: 'كرواتيا',
    labelEn: 'Croatia',
  },
  {
    nationality: 'كندا',
    label: 'كندا',
    labelEn: 'Canada',
  },
  {
    nationality: 'كوبا',
    label: 'كوبا',
    labelEn: 'Cuba',
  },
  {
    nationality: 'كوريا الجنوبية',
    label: 'كوريا الجنوبية',
    labelEn: 'South Korea',
  },
  {
    nationality: 'كوسوفوا',
    label: 'كوسوفوا',
    labelEn: 'Kosovo',
  },
  {
    nationality: 'كولمبيا',
    label: 'كولمبيا',
    labelEn: 'columbia',
  },
  {
    nationality: 'كينيا',
    label: 'كينيا',
    labelEn: 'Kenya',
  },
  {
    nationality: 'لاتفيا',
    label: 'لاتفيا',
    labelEn: 'Latvia',
  },
  {
    nationality: 'لبنان',
    label: 'لبنان',
    labelEn: 'Lebanon',
  },
  {
    nationality: 'لوكسمبورغ',
    label: 'لوكسمبورغ',
    labelEn: 'Luxembourg',
  },
  {
    nationality: 'ليبيا',
    label: 'ليبيا',
    labelEn: 'Libya',
  },
  {
    nationality: 'مالي',
    label: 'مالي',
    labelEn: 'Mali',
  },
  {
    nationality: 'ماليزيا',
    label: 'ماليزيا',
    labelEn: 'Malaysia',
  },
  {
    nationality: 'مدغشقر',
    label: 'مدغشقر',
    labelEn: 'Madagascar',
  },
  {
    nationality: 'مصر',
    label: 'مصر',
    labelEn: 'Egypt',
  },
  {
    nationality: 'ملاوي',
    label: 'ملاوي',
    labelEn: 'Malawi',
  },
  {
    nationality: 'موريتانيا',
    label: 'موريتانيا',
    labelEn: 'Mauritania',
  },
  {
    nationality: 'موريشيوس',
    label: 'موريشيوس',
    labelEn: 'Mauritius',
  },
  {
    nationality: 'نيبال',
    label: 'نيبال',
    labelEn: 'Nepal',
  },
  {
    nationality: 'نيجيريا',
    label: 'نيجيريا',
    labelEn: 'Nigeria',
  },
  {
    nationality: 'نيوزيلندا',
    label: 'نيوزيلندا',
    labelEn: 'New Zealand',
  },
  {
    nationality: 'هنغاريا',
    label: 'هنغاريا',
    labelEn: 'Hungary',
  },
  {
    nationality: 'هولندا',
    label: 'هولندا',
    labelEn: 'Netherlands',
  },
]

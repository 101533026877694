import React, { FunctionComponent, Suspense, useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import KeepAliveModalLaborer from 'components/KeepAliveLaborerModal/KeepAliveLaborerModal'
import SuspensePreloader from 'ui-components/loading/SuspensePreloader/SuspensePreloader'
import { Routes } from 'containers/Routes/routePaths'
import { useTitle } from 'common/hooks/useTitle'
import KeepAliveModal from 'components/KeepAliveModal/KeepAliveModal'
import { useLanguageDetector } from 'common/hooks/useLanguageDetector'
import NewRelic from 'components/NewRelic/NewRelic'
import authManager from 'common/utils/auth/authManager'
import JWTToken from 'common/utils/auth/jwtToken'
import { RootState } from 'redux/rootReducer'
import { useVipRoute } from 'common/hooks/useVipRoute'
import LanguageModal from 'components/LanguageModal/LanguageModal'
import { setLanguage } from 'redux/actionCreators/language'
import { Lang } from 'common/types/commonTypes'
import AutoLoginFallback from 'components/AutoLoginFallback/AutoLoginFallback'

import i18n from '../i18n'

const AppLayout = React.lazy(() => import('./AppLayout/AppLayout'))
const VipLayout = React.lazy(() => import('./VipUser/VipLayout/VipLayout'))
const UnifyJourneyLayout = React.lazy(() => import('./UnifyJourneyLayout/UnifyJourneyLayout'))

let interval: NodeJS.Timeout
const App: FunctionComponent = () => {
  useLanguageDetector(i18n)
  useTitle()
  const { pathname, search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const rmToken = urlParams.get('token') || ''
  const auth = useSelector<RootState, RootState['auth']>((state) => state.auth)
  const { isVip: isVipRoute, getRoute } = useVipRoute()
  const token = new JWTToken('rm', rmToken)
  const isVip = token.getUserType()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLaborer = authManager.getContractAuthToken() && !auth.loggedIn

  useEffect(() => {
    if (auth.loggedIn) token.removeToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loggedIn])

  useEffect(() => {
    const path = pathname + search
    if (!isVipRoute && authManager.isVipUser() && path.includes('/app/')) history.replace(getRoute(path as Routes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])

  useEffect(() => {
    interval = setInterval(() => {
      const labibaDiv = document.getElementById('labibaWebBotDiv')
      if (labibaDiv) {
        labibaDiv.style.display = isVipRoute ? 'none' : 'block'
        clearInterval(interval)
      }
    }, 100)
  }, [isVipRoute])

  // this is temporary until there are no translations for the entire platform

  useEffect(() => {
    if (!(pathname.includes(Routes.LOGIN_LABORER) || isLaborer)) {
      dispatch(setLanguage(localStorage.getItem('prevLang') as Lang))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch])

  return (
    <Suspense fallback={<SuspensePreloader wholeView />}>
      <AutoLoginFallback>
        <Switch>
          {authManager.isVipUser() || isVip ? (
            <Route path={Routes.VIP}>
              <VipLayout />
            </Route>
          ) : (
            <Route path={Routes.APP}>
              <AppLayout />
            </Route>
          )}
          <Route path='/'>
            <UnifyJourneyLayout />
          </Route>
        </Switch>
        <KeepAliveModal />
        <KeepAliveModalLaborer />
      </AutoLoginFallback>
      <NewRelic />
      <LanguageModal />
    </Suspense>
  )
}

export default App
